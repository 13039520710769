<template>
    <a
        class="menu-dropdown__company"
        :class="{'menu-dropdown__company--current': current}"
        @click="loadDomain"
    >
        <div class="company__wrap" v-if="domain">
            <logo
                :logo="domain.icon"
                :company-name="domain.name"
            />
            <h3 class="company__name">{{ domain.name }}</h3>
            <svg class="company__angle" v-show="current" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L4.5 4.5L8 1" stroke="white" stroke-width="1.5"/>
            </svg>
        </div>

        <i
            v-if="domain && domain.is_block"
            class="company__blocked-icon"
            v-tooltip="{
                content: 'Портал заблокирован за неуплату',
                classes: 'tooltip--dark'
            }"
        />
    </a>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "Company",
        components: {
            Logo : () => import('./Logo')
        },
        props: {
            domain: {
                required: true
            },
            current: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        methods: {
            async loadDomain() {
                if (this.domain && this.domain.link && !this.current) {
                     try {
                         const request = session.get(this.domain.link);
                         let response = await request;

                         window.location.href = response.data.link;
                     } catch (err) {
                         console.error(err)
                     }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .menu-dropdown__company {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (min-width: 1001px) {
            padding: 10px;
        }

        @media screen and (max-width: 1000px) {
            .dropdown__body & {
                padding: 10px;
            }
        }

        .company {
            &__wrap {
                width: 100%;
                display: flex;
                align-items: center;
            }
            &__name {
                width: 100%;
                margin-left: 10px;
                display: block;
                overflow: hidden;
                font-weight: 500;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-word;

                @include below($tablet) {
                    padding-right: 10px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                @include below($mobile) {
                    padding-right: 10px;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
            &__angle {
                margin-left: 10px;
                transition: transform $ease;
            }
            &__blocked-icon {
                @include icon($sidebar-locked, 13px);
            }
        }
    }
</style>
